import React, {useEffect, useState} from "react";
import {Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import PrimaryLayout from "../layouts/PrimaryLayout";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Ornament from "../images/background-ornament.png";
import Link from "../components/Link/Link";
import Sarunas from "../images/team/sarunas-new.png";
import Aleksandras from "../images/team/aleksandras-new.png";
import Simona from "../images/team/simona-new.png";
import "swiper/css";
import "swiper/css/pagination";

// Testimonials

import Testimonials1 from "../images/testimonials/1.svg";
import Testimonials2 from "../images/testimonials/2.png";
import Testimonials3 from "../images/testimonials/3.png";
import Testimonials4 from "../images/testimonials/4.png";

// DEFI

import Defi1 from "../images/portfolio/defi/1.png";
import Defi2 from "../images/portfolio/defi/2.png";
import Defi3 from "../images/portfolio/defi/3.png";
import Defi4 from "../images/portfolio/defi/4.png";
import Defi5 from "../images/portfolio/defi/5.png";
import Defi6 from "../images/portfolio/defi/6.png";
import Defi7 from "../images/portfolio/defi/7.png";
import Defi8 from "../images/portfolio/defi/8.png";
import Defi9 from "../images/portfolio/defi/9.png";
import Defi10 from "../images/portfolio/defi/10.png";
import Defi11 from "../images/portfolio/defi/11.png";
import Defi12 from "../images/portfolio/defi/12.png";

// NFT Infra

import NftInfra1 from "../images/portfolio/nft-infra/1.png";
import NftInfra2 from "../images/portfolio/nft-infra/2.png";
import NftInfra3 from "../images/portfolio/nft-infra/3.png";
import NftInfra4 from "../images/portfolio/nft-infra/4.png";
import NftInfra5 from "../images/portfolio/nft-infra/5.png";
import NftInfra6 from "../images/portfolio/nft-infra/6.png";
import NftInfra7 from "../images/portfolio/nft-infra/7.png";

// Infra

import Infra1 from "../images/portfolio/infra/1.png";
import Infra2 from "../images/portfolio/infra/2.png";
import Infra3 from "../images/portfolio/infra/3.png";
import Infra4 from "../images/portfolio/infra/4.png";
import Infra5 from "../images/portfolio/infra/5.png";
import Infra6 from "../images/portfolio/infra/6.png";
import Infra7 from "../images/portfolio/infra/7.png";

// Launchpads

import LaunchPads1 from "../images/portfolio/launchpads/1.png";
import LaunchPads2 from "../images/portfolio/launchpads/2.png";
import LaunchPads3 from "../images/portfolio/launchpads/3.png";
import LaunchPads4 from "../images/portfolio/launchpads/4.png";
import LaunchPads5 from "../images/portfolio/launchpads/5.png";

// Gaming

import Gaming1 from "../images/portfolio/gaming/1.png";
import Gaming2 from "../images/portfolio/gaming/2.png";
import Gaming3 from "../images/portfolio/gaming/3.png";
import Gaming4 from "../images/portfolio/gaming/4.png";
import Gaming5 from "../images/portfolio/gaming/5.png";
import Gaming6 from "../images/portfolio/gaming/6.png";

// LP

import LP1 from "../images/portfolio/lp/4.png";
import LP2 from "../images/portfolio/lp/2.png";
import LP3 from "../images/portfolio/lp/3.png";

gsap.registerPlugin(ScrollTrigger);

export default function Home() {
    const [showModal, setShowModal] = useState(false);
    const [showTestimonialModal, setShowTestimonialModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [modalTestimonialContent, setModalTestimonialContent] = useState({});
    const [activeFilter, setActiveFilter] = useState("DEFI");

    useEffect(() => {
        let tl = gsap.timeline();

        tl.to(".text-reveal", {
            duration: 2,
            y: 0,
            opacity: 1,
            ease: "power4",
            stagger: 0.2,
            delay: 0.3,
        });

        gsap.timeline().to(".mobile .logoWrap .logo-top-row", {
            duration: 1.7,
            x: 0,
            ease: "power4",
            stagger: 0,
            delay: 1.7,
        });

        gsap.timeline().to(".mobile .logoWrap .logo-bottom-row", {
            duration: 2.7,
            y: 0,
            ease: "power4",
            stagger: 0,
            delay: 2.3,
        });

        gsap
            .timeline({
                scrollTrigger: {
                    trigger: ".before-last-section",
                    scrub: true,
                    start: "top 45%",
                    end: "bottom bottom",
                },
            })
            .add("start")
            .to(
                ".desktop .logoWrap",
                {
                    backgroundColor: "#fff",
                },
                "start"
            )
            .to(
                "body",
                {
                    backgroundColor: "#fff",
                },
                "start"
            )
            .to(
                ".bottomShadow",
                {
                    opacity: 0,
                    zIndex: "-1",
                },
                "start"
            )
            .to(
                ".text-wrap p",
                {
                    color: "#010101",
                },
                "start"
            )
            .to(
                ".inverted-link svg path",
                {
                    fill: "#010101",
                },
                "start"
            )
            .to(
                ".inverted-link",
                {
                    color: "#010101",
                },
                "start"
            )
            .to(
                ".desktop .logo-top-row path",
                {
                    fill: "#010101",
                },
                "start"
            )
            .to(
                ".desktop .logo-bottom-text path",
                {
                    fill: "#010101",
                },
                "start"
            )
            .to(
                ".desktop .logo-bottom-text rect",
                {
                    fill: "#010101",
                },
                "start"
            );
    }, []);

    const teamMembers = [
        {
            image: Sarunas,
            name: "Sarunas Legeckas",
            position: "General Partner",
            subheading:
                "Advisor of multiple crypto projects (30+) and has been an active investor since 2017.",
            description:
                "Experienced as a General Manager at Neobank N26 and Barclays Bank, featured in The New York Times, CNN, Irish Times, and other leading media outlets across the globe. Recognized as one of the top 100 digital challengers (NEWEUROPE100) in the CEE region by Google and the Financial Times.",
        },
        {
            image: Aleksandras,
            name: "Aleksandras Gaska",
            position: "General Partner",
            subheading:
                "Advisor, active investor, founder of BlockWallet, and co-founder of multiple crypto projects with 5+ years of experience in the market.",
            description:
                "Experienced in business development, operations, and Web3 marketing since 2017. As founder acts in developing business growth and product development. As a participant in Web3 an active builder, is also a researcher of the market trends, a trader, and a huge enthusiast of the market.",
        },
        {
            image: Simona,
            name: "Simona Puidokaite",
            position: "Investment Manager",
            subheading: "Investment analyst and investor since 2018. ",
            description:
                "Held positions at multiple private equity funds and financial institutions. Awardee of the Presidential Scholarship by the Republic of Lithuania for excellence in research. Previous experience in sourcing, analyzing, and performing due diligence on buyout, growth and venture capital investments. Supported the development and execution of business growth strategies.",
        },
    ];

    const testimonials = [
        {
            logo: Testimonials1,
            person: "Lauris Zvirbulis",
            position: "CEO of Insrt Finance",
            testimonial:
                "“The Founderheads team joined our funding round after we had already launched our product. Their decision to invest was based on their experience as actual users of our product, which is a strong signal of confidence in our offering, and demonstrates their deep understanding of the industry. Throughout our partnership, Sarunas and the team have been incredibly supportive and helpful, providing valuable insights. They have trusted our ability to execute and pivot when needed. I highly recommend having Founderheads on any founder's cap table.”",
        },
        {
            logo: Testimonials2,
            person: "Jong-Moon Kim",
            position: "CEO of ETH.ID",
            testimonial:
                "“Founderheads is a well-respected firm that moves quickly and decisively.  Within the first week, they had lined up several introductions to strategic partners.  As a YC-alum, I have worked with a large variety of investors and I can confidently say that Founderheads has been one of the most effective partners on the cap table.”",
        },
        {
            logo: Testimonials3,
            person: "Christian Lupascu, PhD",
            position: "CEO of Omnia Protocol",
            testimonial:
                "“From the moment we embarked on our startup journey, Founderheads has provided essential guidance and support. Their unparalleled expertise, combined with a genuine founder-first mentality, has been pivotal in shaping our vision and trajectory. Their hands-on approach and unwavering support have not only fuelled our growth but have also instilled a sense of confidence and resilience in our team. In a sea of investors and incubators, Founderheads stands as a beacon of true partnership and mentorship.”",
        },
        {
            logo: Testimonials4,
            person: "Yurii Kyparus",
            position: "CEO of Wallchain",
            testimonial:
                "“Working with Founderheads is like having a team of supportive co-founders who natively understand your challenges and can relate from their own experience.”",
        },
    ];

    const toggleModal = (content) => {
        setShowModal(true);
        setModalContent(content);
    };

    const toggleTestimonialModal = (content) => {
        setShowTestimonialModal(true);
        setModalTestimonialContent(content);
    };

    return (
        <>
            {showModal && (
                <div className="modal">
                    <div className="overlay"></div>
                    <div className="modal-content">
                        <div className="modal-close">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                    setShowModal(false);
                                    setModalContent({});
                                }}
                            >
                                <path
                                    d="M18.4556 1.48529L1.485 18.4559"
                                    stroke="#010101"
                                    strokeWidth="2"
                                />
                                <path
                                    d="M18.4556 18.4558L1.48501 1.48526"
                                    stroke="#010101"
                                    strokeWidth="2"
                                />
                            </svg>
                        </div>
                        <div className="member-image">
                            <img
                                src={modalContent.image}
                                alt={modalContent.name + "| Founderheads"}
                            />
                        </div>
                        <div className="member-name">{modalContent.name}</div>
                        <div className="member-position">{modalContent.position}</div>
                        <div className="member-subheading">{modalContent.subheading}</div>
                        <div className="member-description">{modalContent.description}</div>
                    </div>
                </div>
            )}
            {showTestimonialModal && (
                <div className="modal">
                    <div className="overlay"></div>
                    <div className="modal-content">
                        <div className="modal-close">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                    setShowTestimonialModal(false);
                                    setModalTestimonialContent({});
                                }}
                            >
                                <path
                                    d="M18.4556 1.48529L1.485 18.4559"
                                    stroke="#010101"
                                    strokeWidth="2"
                                />
                                <path
                                    d="M18.4556 18.4558L1.48501 1.48526"
                                    stroke="#010101"
                                    strokeWidth="2"
                                />
                            </svg>
                        </div>
                        <div className="modal-logo">
                            <img src={modalTestimonialContent.logo} alt=""/>
                        </div>
                        <div className="testimonial-person">
                            {modalTestimonialContent.person}
                        </div>
                        <div className="testimonial-position">
                            {modalTestimonialContent.position}
                        </div>
                        <div className="testimonial-text">
                            {modalTestimonialContent.testimonial}
                        </div>
                    </div>
                </div>
            )}
            <div className="desktop">
                <PrimaryLayout
                    pageTitle={"Founderheads"}
                    metaDescription={
                        "A boutique Web3 VC with a founder mentality to help you navigate the crypto industry"
                    }
                >
                    <div className="sections-wrap">
                        <div className="section">
                            <div className="text-wrap smaller">
                                <p className="text-reveal">
                                    We are anything but a standard VC company. We invest in
                                    founders, not only ideas.
                                </p>
                            </div>
                        </div>
                        <div className="section half-section before-last-section gap-text">
                            <div className="text-wrap">
                                <p className="text-reveal">
                                    Our vision - to fuel and support every future crypto unicorn
                                    globally.
                                </p>
                                <p className="text-reveal">
                                    Our mission - to help great entrepreneurs thrive in the
                                    cryptocurrency industry, bringing a positive impact to the
                                    web3 revolution.
                                </p>
                            </div>
                        </div>
                        <div className="section half-section"></div>
                        <div className="section team-section">
                            <div className="section-heading">TEAM</div>
                            <div className="team-members-grid">
                                {teamMembers.map((item, key) => {
                                    const {image, name, position} = item;

                                    return (
                                        <div
                                            className="team-member"
                                            key={key}
                                            onClick={() => toggleModal(item)}
                                        >
                                            <div className="member-image">
                                                <img src={image} alt={name + "| Founderheads"}/>
                                            </div>
                                            <div className="info">
                                                <div className="member-name">{name}</div>
                                                <div className="member-position">{position}</div>
                                                <div className="arrow-plus">
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8 0V16"
                                                            stroke="#00D283"
                                                            stroke-width="2"
                                                        />
                                                        <path
                                                            d="M16 8L-2.98023e-07 8"
                                                            stroke="#00D283"
                                                            stroke-width="2"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="section">
                            <div className="section-heading">PORTFOLIO</div>
                            <div className="filters">
                                <div
                                    className={
                                        activeFilter === "DEFI" ? "filter is-active" : "filter"
                                    }
                                    onClick={() => setActiveFilter("DEFI")}
                                >
                                    DEFI
                                </div>
                                <div
                                    className={
                                        activeFilter === "LAUNCHPADS" ? "filter is-active" : "filter"
                                    }
                                    onClick={() => setActiveFilter("LAUNCHPADS")}
                                >
                                    LAUNCHPADS
                                </div>
                                <div
                                    className={
                                        activeFilter === "OTHERS" ? "filter is-active" : "filter"
                                    }
                                    onClick={() => setActiveFilter("OTHERS")}
                                >
                                    INFRA
                                </div>
                                <div
                                    className={
                                        activeFilter === "GAMING" ? "filter is-active" : "filter"
                                    }
                                    onClick={() => setActiveFilter("GAMING")}
                                >
                                    GAMING
                                </div>
                                <div
                                    className={
                                        activeFilter === "NFT" ? "filter is-active" : "filter"
                                    }
                                    onClick={() => setActiveFilter("NFT")}
                                >
                                    TOOLS
                                </div>
                                <div
                                    className={
                                        activeFilter === "LP" ? "filter is-active" : "filter"
                                    }
                                    onClick={() => setActiveFilter("LP")}
                                >
                                    LP POSITIONS
                                </div>
                            </div>
                            <div className="logos-wrap">
                                {activeFilter === "DEFI" && (
                                    <div className="logos">
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={Defi1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={Defi2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={Defi3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap ">
                                            <div className="img-wrap">
                                                <img src={Defi4} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={Defi5} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={Defi6} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap ">
                                            <div className="img-wrap">
                                                <img src={Defi7} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={Defi8} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={Defi9} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap">
                                                <img src={Defi10} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap">
                                                <img src={Defi11} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r no-border-b">
                                            <div className="img-wrap">
                                                <img src={Defi12} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeFilter === "LAUNCHPADS" && (
                                    <div className="logos">
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={LaunchPads1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={LaunchPads2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap nft-2">
                                                <img src={LaunchPads3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap nft-2">
                                                <img src={LaunchPads4} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r no-border-b">
                                            <div className="img-wrap nft-2">
                                                <img src={LaunchPads5} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeFilter === "OTHERS" && (
                                    <div className="logos">
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={Infra1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap nft-2 ">
                                                <img src={Infra2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={Infra3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap nft-2">
                                                <img src={Infra4} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap nft-2">
                                                <img src={Infra5} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={Infra6} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap">
                                                <img src={Infra7} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap">

                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeFilter === "GAMING" && (
                                    <div className="logos">
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={Gaming2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap nft-2">
                                                <img src={Gaming3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={Gaming6} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap nft-2">
                                                <img src={Gaming5} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap nft-2">

                                                <img src={Gaming4} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r no-border-b">
                                            <div className="img-wrap nft-2">
                                                <img src={Gaming1} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeFilter === "NFT" && (
                                    <div className="logos">
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={NftInfra1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap nft-2">
                                                <img src={NftInfra2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={NftInfra3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={NftInfra4} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={NftInfra5} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap">
                                                <img src={NftInfra6} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                            <div className="img-wrap">
                                                <img src={NftInfra7} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-b">
                                        </div>
                                    </div>
                                )}
                                {activeFilter === "LP" && (
                                    <div className="logos">
                                        <div className="wrap">
                                            <div className="img-wrap">
                                                <img src={LP1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="img-wrap nft-2">
                                                <img src={LP2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r">
                                            <div className="img-wrap nft-2">
                                                <img src={LP3} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wrap no-border-r no-border-b"></div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="section before-last-section">
                            <div className="section-heading">TESTIMONIALS</div>
                            <div className="testimonials">
                                {testimonials.map((item, key) => {
                                    return (
                                        <div
                                            className="testimonial-card"
                                            key={key}
                                            onClick={() => toggleTestimonialModal(item)}
                                        >
                                            <div className="heading">
                                                <div className="logo">
                                                    <img src={item.logo} alt=""/>
                                                </div>
                                                <div className="arrow">
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8 0V16"
                                                            stroke="#00D283"
                                                            strokeWidth="2"
                                                        />
                                                        <path
                                                            d="M16 8L-2.98023e-07 8"
                                                            stroke="#00D283"
                                                            strokeWidth="2"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="testimonial">{item.testimonial}</div>
                                            <div className="person-info">
                                                <div className="name">{item.person}</div>
                                                <div className="position">{item.position}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="section last-section">
                            <div className="contentWrap">
                                <div className="contactUs">
                                    <div className="heading">Let’s talk</div>
                                    <Link
                                        text={"hello@founderheads.com"}
                                        href={"mailto:hello@founderheads.com"}
                                        className="inverted-link"
                                    >
                                        hello@founderheads.com
                                    </Link>
                                    <div className="heading heading--margin-top">For project application</div>
                                    <Link
                                        text={"submit a form"}
                                        href={"https://show.forms.app/foundvc/contact"}
                                        target="_blank"
                                        className="inverted-link"
                                    >
                                        Submit a form
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </PrimaryLayout>
            </div>
            <div className="mobile">
                <div className="heading">
                    <div className="logoWrap">
                        <svg
                            width="572"
                            height="202"
                            viewBox="0 0 572 202"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g className="logo-top-row">
                                <path
                                    d="M339.533 1.33496V85.8597H370.674C396.365 85.8597 413.715 72.0688 413.715 43.5973C413.715 15.1258 396.365 1.33496 370.674 1.33496H339.533ZM357.105 71.1791V16.0156H371.119C386.578 16.0156 395.586 25.8026 395.586 43.5973C395.586 61.5032 386.578 71.1791 371.119 71.1791H357.105Z"
                                    fill="white"
                                />
                                <path
                                    d="M306.513 82.968L278.709 1.33496H244.677V85.8597H262.138V4.2266L290.053 85.8597H323.974V1.33496H306.513V82.968Z"
                                    fill="white"
                                />
                                <path
                                    d="M194.49 87.1938C219.403 87.1938 229.635 73.959 229.635 51.9381V1.33447H212.063V53.4951C212.063 66.7299 206.057 73.2917 194.49 73.2917C182.924 73.2917 176.807 66.8411 176.807 53.7176V53.4951V1.33447H159.235V51.9381V52.1605C159.235 74.0702 169.578 87.1938 194.49 87.1938Z"
                                    fill="white"
                                />
                                <path
                                    d="M108.821 87.1939C133.734 87.1939 151.084 70.1778 151.084 43.597C151.084 17.0162 133.734 0 108.821 0C83.9089 0 66.5591 17.0162 66.5591 43.597C66.5591 70.1778 83.9089 87.1939 108.821 87.1939ZM108.821 73.2918C94.252 73.2918 84.6874 61.6141 84.6874 43.597C84.6874 25.5798 94.252 13.9021 108.821 13.9021C123.391 13.9021 132.955 25.5798 132.955 43.597C132.955 61.6141 123.391 73.2918 108.821 73.2918Z"
                                    fill="white"
                                />
                                <path
                                    d="M56.9553 1.33496H0V85.8597H17.6958V52.1239H56.288V35.1943H17.6958V18.2646H56.9553V1.33496Z"
                                    fill="white"
                                />
                                <path
                                    d="M483.535 52.1857H443.719V69.0536H485.314V85.8597H426.147V1.33496H484.313V18.2028H443.719V35.1449H483.535V52.1857Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M551.091 49.2694L572 85.8597H551.981L533.63 52.161H518.171V85.8597H500.599V1.33496H534.186C554.539 1.33496 567.218 11.7893 567.218 27.9158C567.218 38.815 561.435 45.0431 551.091 49.2694ZM541.105 18.2646H518.059V35.1943H541.105C545.78 35.1943 549.57 31.4044 549.57 26.7295C549.57 22.0545 545.78 18.2646 541.105 18.2646Z"
                                    fill="white"
                                />
                            </g>
                            <g className="logo-bottom-row">
                                <g className="logo-bottom-text">
                                    <path
                                        d="M51.6664 137.498H17.5722V103.638H0V188.163H17.5722V154.402H51.6664V188.163H69.2386V103.638H51.6664V137.498Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M244.677 103.638V188.163H275.817C301.508 188.163 318.858 174.372 318.858 145.901C318.858 117.429 301.508 103.638 275.817 103.638H244.677ZM262.249 171.382V120.543H276.262C291.721 120.543 300.73 128.106 300.73 145.901C300.73 163.806 291.721 171.382 276.262 171.382H262.249Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M359.49 189.473C381.51 189.473 396.191 179.575 396.191 162.892C396.191 149.435 387.961 142.429 372.057 138.981L360.046 136.312C348.368 133.642 343.808 131.641 343.808 125.635C343.808 119.74 350.703 116.181 359.156 116.181C367.497 116.181 373.503 119.184 375.727 126.191H394.078C391.631 112.066 379.953 102.279 359.156 102.279C340.694 102.279 326.458 112.511 326.458 127.192C326.458 140.538 335.8 147.656 349.925 150.659L361.936 153.328C373.392 155.886 378.73 157.999 378.73 164.672C378.73 171.234 371.946 175.571 360.157 175.571C350.926 175.571 343.585 171.122 342.362 162.114H324.011C326.236 179.352 340.249 189.473 359.49 189.473Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M140.306 154.49H100.49V171.358H142.086V188.164H82.9182V103.639H141.085V120.507H100.49V137.449H140.306V154.49Z"
                                        fill="white"
                                    />
                                    <rect
                                        x="168.926"
                                        y="154.428"
                                        width="49.4297"
                                        height="16.9297"
                                        fill="white"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M237.83 188.164L209.803 103.639H177.551L149.524 188.164H167.986L168.006 188.102H167.945L193.649 107.284L193.649 107.286L193.677 107.198L204.624 141.716L212.131 165.364H212.139L218.435 185.223L219.291 187.917L219.289 187.915L219.368 188.164H237.83Z"
                                        fill="white"
                                    />
                                </g>
                                <path
                                    d="M406.081 187.498H411.433L404.869 201.637H415.069L422.744 189.316V172.047H406.081V187.498Z"
                                    fill="#00D283"
                                />
                            </g>
                        </svg>
                    </div>
                    <div className="mobile-wrap">
                        <Link
                            href="mailto:hello@founderheads.co"
                            target="_blank"
                            rel="noopener"
                            className="text-reveal"
                        >
                            Let's talk
                        </Link>
                        <Link
                            href="https://show.forms.app/foundvc/contact"
                            target="_blank"
                            rel="noopener"
                            className="text-reveal"
                        >
                            Project application
                        </Link>
                    </div>


                </div>
                <div className="ornamentWrap">
                    <div
                        className="ornament ornament-reveal"
                        style={{backgroundImage: `url(${Ornament})`}}
                    />
                </div>
                <div className="content-wrapper">
                    <div className="text-wrap smaller">
                        <p className="text-reveal">
                            We are anything but a standard VC company. We invest in founders,
                            not only ideas.
                        </p>
                    </div>
                    <div className="text-wrap">
                        <p className="text-reveal">
                            Our vision - to fuel and support every future crypto unicorn
                            globally.
                        </p>
                    </div>
                    <div className="text-wrap">
                        <p className="text-reveal">
                            Our mission - to help great entrepreneurs thrive in the
                            cryptocurrency industry, bringing a positive impact to the web3
                            revolution.
                        </p>
                    </div>
                    <div className="team-section">
                        <div className="section-heading">TEAM</div>
                        <div className="team-members-grid">
                            {teamMembers.map((item, key) => {
                                const {image, name, position} = item;

                                return (
                                    <div
                                        className="team-member"
                                        key={key}
                                        onClick={() => toggleModal(item)}
                                    >
                                        <div className="member-image">
                                            <img src={image} alt={name + "| Founderheads"}/>
                                        </div>
                                        <div className="info">
                                            <div className="member-name">{name}</div>
                                            <div className="member-position">{position}</div>
                                            <div className="arrow-plus">
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M8 0V16" stroke="#00D283" stroke-width="2"/>
                                                    <path
                                                        d="M16 8L-2.98023e-07 8"
                                                        stroke="#00D283"
                                                        stroke-width="2"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="logos-section">
                        <div className="section-heading">PORTFOLIO</div>
                        <div className="filters">
                            <div
                                className={
                                    activeFilter === "DEFI" ? "filter is-active" : "filter"
                                }
                                onClick={() => setActiveFilter("DEFI")}
                            >
                                DEFI
                            </div>
                            <div
                                className={
                                    activeFilter === "LAUNCHPADS" ? "filter is-active" : "filter"
                                }
                                onClick={() => setActiveFilter("LAUNCHPADS")}
                            >
                                LAUNCHPADS
                            </div>
                            <div
                                className={
                                    activeFilter === "OTHERS" ? "filter is-active" : "filter"
                                }
                                onClick={() => setActiveFilter("OTHERS")}
                            >
                                INFRA
                            </div>
                            <div
                                className={
                                    activeFilter === "GAMING" ? "filter is-active" : "filter"
                                }
                                onClick={() => setActiveFilter("GAMING")}
                            >
                                GAMING
                            </div>
                            <div
                                className={
                                    activeFilter === "NFT" ? "filter is-active" : "filter"
                                }
                                onClick={() => setActiveFilter("NFT")}
                            >
                                TOOLS
                            </div>
                            <div
                                className={
                                    activeFilter === "LP" ? "filter is-active" : "filter"
                                }
                                onClick={() => setActiveFilter("LP")}
                            >
                                LP POSITIONS
                            </div>
                        </div>
                        <div className="logos-wrap">
                            {activeFilter === "DEFI" && (
                                <div className="logos grid-2">
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Defi1} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={Defi2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Defi3} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={Defi4} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Defi5} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={Defi6} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Defi7} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={Defi8} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Defi9} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={Defi10} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-b">
                                        <div className="img-wrap">
                                            <img src={Defi11} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r no-border-b">
                                        <div className="img-wrap">
                                            <img src={Defi12} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeFilter === "NFT" && (
                                <div className="logos grid-2">
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={NftInfra1} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                    <div className="img-wrap nft-2">
                                            <img src={NftInfra2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={NftInfra3} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={NftInfra4} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={NftInfra5} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={NftInfra6} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-b">
                                        <div className="img-wrap">
                                            <img src={NftInfra7} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeFilter === "LAUNCHPADS" && (
                                <div className="logos">
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={LaunchPads1} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap">
                                            <img src={LaunchPads2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap nft-2">
                                            <img src={LaunchPads3} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap nft-2">
                                            <img src={LaunchPads4} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-b">
                                        <div className="img-wrap nft-2">
                                            <img src={LaunchPads5} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeFilter === "OTHERS" && (
                                <div className="logos">
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Infra1} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap nft-2">
                                            <img src={Infra2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Infra3} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap nft-2">
                                            <img src={Infra4} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Infra5} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap nft-2">
                                            <img src={Infra6} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-b">
                                        <div className="img-wrap nft-2">
                                            <img src={Infra7} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeFilter === "GAMING" && (
                                <div className="logos grid-2">
                                    <div className="wrap">
                                        <div className="img-wrap nft-2">
                                            <img src={Gaming2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                    <div className="img-wrap">
                                            <img src={Gaming3} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap">
                                        <div className="img-wrap">
                                            <img src={Gaming6} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap nft-2">
                                            <img src={Gaming5} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-b">
                                        <div className="img-wrap nft-2">
                                            <img src={Gaming4} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r no-border-b">
                                        <div className="img-wrap nft-2">
                                            <img src={Gaming1} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeFilter === "LP" && (
                                <div className="logos grid-2">
                                    <div className="wrap">
                                        <div className="img-wrap">
                                        <img src={LP1} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r">
                                        <div className="img-wrap nft-2">
                                            <img src={LP2} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-b">
                                        <div className="img-wrap nft-2">
                                            <img src={LP3} alt=""/>
                                        </div>
                                    </div>
                                    <div className="wrap no-border-r no-border-b"></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="testimonials-section">
                    <div className="section-heading">TESTIMONIALS</div>
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={50}
                        slidesPerView={1.2}
                        // onSlideChange={() => console.log("slide change")}
                        // onSwiper={(swiper) => console.log(swiper)}
                        pagination={{clickable: true}}
                    >
                        {testimonials.map((item, key) => {
                            return (
                                <SwiperSlide key={key}>
                                    <div className="testimonial-card">
                                        <div className="heading">
                                            <div className="logo">
                                                <img src={item.logo} alt=""/>
                                            </div>
                                        </div>
                                        <div className="person-info">
                                            <div className="name">{item.person}</div>
                                            <div className="position">{item.position}</div>
                                        </div>
                                        <div className="testimonial">{item.testimonial}</div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                <div className="content-wrapper">
                    <div className="ornamentWrap bottom-mobile">
                        <div
                            className="ornament ornament-reveal"
                            style={{backgroundImage: `url(${Ornament})`}}
                        />
                    </div>
                    <div className="contactUs">
                        <div className="heading">Let’s talk</div>
                        <Link
                            text={"hello@founderheads.com"}
                            href={"mailto:hello@founderheads.com"}
                            target="_blank"
                        >
                            hello@founderheads.com
                        </Link>
                        <div className="heading heading--margin-top">
                            For project application
                        </div>
                        <Link
                            text={"submit a form"}
                            href={"https://show.forms.app/foundvc/contact"}
                            target="_blank"
                        >
                            Submit a form
                        </Link>
                    </div>
                    <div className="socials">
                        <Link
                            href="https://www.linkedin.com/company/founderheads/"
                            target="_blank"
                            rel="noopener"
                            className="text-reveal inverted-link"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20.5909 1.5H3.40909C2.35432 1.5 1.5 2.35432 1.5 3.40909V20.5909C1.5 21.6457 2.35432 22.5 3.40909 22.5H20.5909C21.6457 22.5 22.5 21.6457 22.5 20.5909V3.40909C22.5 2.35432 21.6457 1.5 20.5909 1.5ZM8.13791 18.6818H5.322V9.62127H8.13791V18.6818ZM6.70132 8.32595C5.79354 8.32595 5.0595 7.59 5.0595 6.68413C5.0595 5.77827 5.7945 5.04327 6.70132 5.04327C7.60623 5.04327 8.34218 5.77923 8.34218 6.68413C8.34218 7.59 7.60623 8.32595 6.70132 8.32595ZM18.6856 18.6818H15.8716V14.2756C15.8716 13.2247 15.8525 11.873 14.4083 11.873C12.9431 11.873 12.7178 13.0175 12.7178 14.1993V18.6818H9.90382V9.62127H12.6052V10.8593H12.6434C13.0195 10.1472 13.9377 9.396 15.3075 9.396C18.1587 9.396 18.6856 11.2726 18.6856 13.7125V18.6818Z"
                                    fill="#fff"
                                />
                            </svg>
                        </Link>
                        <Link
                            href="https://twitter.com/founderheads"
                            target="_blank"
                            rel="noopener"
                            className="text-reveal inverted-link"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.5508 2.25L9.59466 13.0054L1.5 21.75H3.32178L10.4086 14.094L16.1346 21.75H22.3342L13.8378 10.3896L21.3722 2.25H19.5504L13.0238 9.30105L7.75039 2.25H1.5508ZM4.22986 3.59193H7.07797L19.6547 20.4079H16.8066L4.22986 3.59193Z"
                                    fill="#fff"
                                />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
