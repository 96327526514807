import React from "react";
import * as Styles from "./Link.module.scss";
import cn from "../../utils/cn";

const LinkComponent = ({ text, children, href, className, ...props }) => {
  return (
    <a href={href} className={cn(Styles.linkWrap, className)} {...props}>
      {children}
    </a>
  );
};

export default LinkComponent;
