import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Link from "../components/Link/Link";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Ornament from "../images/background-ornament.png";
import OG from "../images/og.png";
import "../styles/reset.scss";
import "../styles/typo.scss";

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ pageTitle, metaDescription, children }) => {
  useEffect(() => {
    const box = document.querySelector("body");

    gsap.timeline({
      scrollTrigger: {
        trigger: ".desktop .logoWrap",
        pin: true,
        anticipatePin: 1,
        scrub: true,
        start: "top top",
        end: () => box.clientHeight + "px bottom",
        onUpdate: (swiper) => {
          swiper.spacer.style.height = 0;
          swiper.spacer.style.padding = 0;
        },
      },
    });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "html",
          scrub: 0.4,
          start: "top top",
          end: "bottom bottom",
        },
      })
      .to(".ornament-reveal", {
        rotation: box.clientHeight * 0.0175,
        duration: 1.7,
        ease: "none",
      });

    gsap.timeline().to(".text-reveal", {
      duration: 2,
      y: 0,
      opacity: 1,
      ease: "power4",
      stagger: 0.2,
      delay: 0.3,
    });

    gsap.timeline().to(".desktop .logo-top-row", {
      duration: 1.7,
      x: 0,
      ease: "power4",
      stagger: 0,
      delay: 1.7,
    });

    gsap.timeline().to(".desktop .logo-bottom-row", {
      duration: 2.7,
      y: 0,
      ease: "power4",
      stagger: 0,
      delay: 2.3,
    });

    gsap.timeline().to(".ornament-reveal", {
      duration: 3.5,
      rotation: 0,
      scale: 1,
      opacity: 1,
      ease: "power4",
      stagger: 0,
      delay: 2,
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:image" content={OG} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={metaDescription} />
      </Helmet>
      <div className="fixed-top-wrap">
        <Link
          href="mailto:hello@founderheads.com"
          target="_blank"
          rel="noopener"
          className="text-reveal inverted-link"
        >
          Let's talk
        </Link>
        <Link
            href="https://show.forms.app/foundvc/contact"
            target="_blank"
            rel="noopener"
            className="text-reveal inverted-link"
        >
          Project application
        </Link>
      </div>
      <div className="fixed-bottom-wrap">
        <Link
          href="https://www.linkedin.com/company/founderheads/"
          target="_blank"
          rel="noopener"
          className="text-reveal inverted-link"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.4545 2H4.54545C3.13909 2 2 3.13909 2 4.54545V27.4545C2 28.8609 3.13909 30 4.54545 30H27.4545C28.8609 30 30 28.8609 30 27.4545V4.54545C30 3.13909 28.8609 2 27.4545 2ZM10.8505 24.9091H7.096V12.8284H10.8505V24.9091ZM8.93509 11.1013C7.72473 11.1013 6.746 10.12 6.746 8.91218C6.746 7.70436 7.726 6.72436 8.93509 6.72436C10.1416 6.72436 11.1229 7.70563 11.1229 8.91218C11.1229 10.12 10.1416 11.1013 8.93509 11.1013ZM24.9142 24.9091H21.1622V19.0342C21.1622 17.6329 21.1367 15.8307 19.2111 15.8307C17.2575 15.8307 16.9571 17.3567 16.9571 18.9324V24.9091H13.2051V12.8284H16.8069V14.4791H16.8578C17.3593 13.5296 18.5836 12.528 20.41 12.528C24.2116 12.528 24.9142 15.0302 24.9142 18.2833V24.9091Z"
              fill="#fff"
            />
          </svg>
        </Link>
        <Link
          href="https://twitter.com/founderheads"
          target="_blank"
          rel="noopener"
          className="text-reveal inverted-link"
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.06773 3L12.7929 17.3405L2 29H4.42904L13.8782 18.792L21.5128 29H29.779L18.4504 13.8528L28.4963 3H26.0673L17.3651 12.4014L10.3339 3H2.06773ZM5.63982 4.78924H9.43729L26.2063 27.2105H22.4088L5.63982 4.78924Z"
              fill="#fff"
            />
          </svg>
        </Link>
      </div>

      <div className="logoWrap">
        <div className="pin-section">
          <svg
            width="572"
            height="202"
            viewBox="0 0 572 202"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className="logo-top-row">
              <path
                d="M339.533 1.33496V85.8597H370.674C396.365 85.8597 413.715 72.0688 413.715 43.5973C413.715 15.1258 396.365 1.33496 370.674 1.33496H339.533ZM357.105 71.1791V16.0156H371.119C386.578 16.0156 395.586 25.8026 395.586 43.5973C395.586 61.5032 386.578 71.1791 371.119 71.1791H357.105Z"
                fill="white"
              />
              <path
                d="M306.513 82.968L278.709 1.33496H244.677V85.8597H262.138V4.2266L290.053 85.8597H323.974V1.33496H306.513V82.968Z"
                fill="white"
              />
              <path
                d="M194.49 87.1938C219.403 87.1938 229.635 73.959 229.635 51.9381V1.33447H212.063V53.4951C212.063 66.7299 206.057 73.2917 194.49 73.2917C182.924 73.2917 176.807 66.8411 176.807 53.7176V53.4951V1.33447H159.235V51.9381V52.1605C159.235 74.0702 169.578 87.1938 194.49 87.1938Z"
                fill="white"
              />
              <path
                d="M108.821 87.1939C133.734 87.1939 151.084 70.1778 151.084 43.597C151.084 17.0162 133.734 0 108.821 0C83.9089 0 66.5591 17.0162 66.5591 43.597C66.5591 70.1778 83.9089 87.1939 108.821 87.1939ZM108.821 73.2918C94.252 73.2918 84.6874 61.6141 84.6874 43.597C84.6874 25.5798 94.252 13.9021 108.821 13.9021C123.391 13.9021 132.955 25.5798 132.955 43.597C132.955 61.6141 123.391 73.2918 108.821 73.2918Z"
                fill="white"
              />
              <path
                d="M56.9553 1.33496H0V85.8597H17.6958V52.1239H56.288V35.1943H17.6958V18.2646H56.9553V1.33496Z"
                fill="white"
              />
              <path
                d="M483.535 52.1857H443.719V69.0536H485.314V85.8597H426.147V1.33496H484.313V18.2028H443.719V35.1449H483.535V52.1857Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M551.091 49.2694L572 85.8597H551.981L533.63 52.161H518.171V85.8597H500.599V1.33496H534.186C554.539 1.33496 567.218 11.7893 567.218 27.9158C567.218 38.815 561.435 45.0431 551.091 49.2694ZM541.105 18.2646H518.059V35.1943H541.105C545.78 35.1943 549.57 31.4044 549.57 26.7295C549.57 22.0545 545.78 18.2646 541.105 18.2646Z"
                fill="white"
              />
            </g>
            <g className="logo-bottom-row">
              <g className="logo-bottom-text">
                <path
                  d="M51.6664 137.498H17.5722V103.638H0V188.163H17.5722V154.402H51.6664V188.163H69.2386V103.638H51.6664V137.498Z"
                  fill="white"
                />
                <path
                  d="M244.677 103.638V188.163H275.817C301.508 188.163 318.858 174.372 318.858 145.901C318.858 117.429 301.508 103.638 275.817 103.638H244.677ZM262.249 171.382V120.543H276.262C291.721 120.543 300.73 128.106 300.73 145.901C300.73 163.806 291.721 171.382 276.262 171.382H262.249Z"
                  fill="white"
                />
                <path
                  d="M359.49 189.473C381.51 189.473 396.191 179.575 396.191 162.892C396.191 149.435 387.961 142.429 372.057 138.981L360.046 136.312C348.368 133.642 343.808 131.641 343.808 125.635C343.808 119.74 350.703 116.181 359.156 116.181C367.497 116.181 373.503 119.184 375.727 126.191H394.078C391.631 112.066 379.953 102.279 359.156 102.279C340.694 102.279 326.458 112.511 326.458 127.192C326.458 140.538 335.8 147.656 349.925 150.659L361.936 153.328C373.392 155.886 378.73 157.999 378.73 164.672C378.73 171.234 371.946 175.571 360.157 175.571C350.926 175.571 343.585 171.122 342.362 162.114H324.011C326.236 179.352 340.249 189.473 359.49 189.473Z"
                  fill="white"
                />
                <path
                  d="M140.306 154.49H100.49V171.358H142.086V188.164H82.9182V103.639H141.085V120.507H100.49V137.449H140.306V154.49Z"
                  fill="white"
                />
                <rect
                  x="168.926"
                  y="154.428"
                  width="49.4297"
                  height="16.9297"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M237.83 188.164L209.803 103.639H177.551L149.524 188.164H167.986L168.006 188.102H167.945L193.649 107.284L193.649 107.286L193.677 107.198L204.624 141.716L212.131 165.364H212.139L218.435 185.223L219.291 187.917L219.289 187.915L219.368 188.164H237.83Z"
                  fill="white"
                />
              </g>
              <path
                d="M406.081 187.498H411.433L404.869 201.637H415.069L422.744 189.316V172.047H406.081V187.498Z"
                fill="#00D283"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="ornamentWrap">
        <div
          className="ornament ornament-reveal"
          style={{ backgroundImage: `url(${Ornament})` }}
        />
      </div>
      <div className="content">{children}</div>
      <div className="bottomShadow" />
    </>
  );
};

export default Layout;
